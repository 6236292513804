import Logo from "./logo";
import SpeedReadr from "./speedReader";
import "./App.css";
import { useState } from "react";
import Convert from "./imageConverter";

function App() {
  const [text, setText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState({name: "No file selected"});


  function handleFormSubmit(event) {
    event.preventDefault();

    // Check if "Scan my Photo" button was clicked
    if (event.nativeEvent.submitter.id === "submit-photo") {
      const file = event.target.elements["my-photo"].files[0];
      setIsLoading(true);
      // Call imageConverter function to perform OCR on uploaded image
      Convert(file).then(({ text, m }) => {
        const inputText = text;
        const inputArray = inputText.split(" ");
        let spaces = [];
        for (let i = 0; i < inputArray.length; i++) {
          if (inputArray[i].includes("\n\n")) {
            let splitEntry = inputArray[i].split("\n\n");
            spaces.push(splitEntry[0] + "\n\n");
            spaces.push(splitEntry[1]);
          } else if (inputArray[i].includes("\n")) {
            let splitEntry = inputArray[i].split("\n");
            spaces.push(splitEntry[0] + "\n");
            spaces.push(splitEntry[1]);
          } else {
            spaces.push(inputArray[i]);
          }
        }
        let firstHalfArr = [];
        let secondHalfArr = [];
        spaces.forEach((word) => {
          let firstHalf = word.slice(0, Math.floor(word.length / 2));
          let secondHalf = word.slice(Math.floor(word.length / 2), word.length);
          firstHalfArr.push('<span class="bold">' + firstHalf + "</span>");
          secondHalfArr.push(secondHalf);
        });
        let answer = [];
        for (let i = 0; i < firstHalfArr.length; i++) {
          answer.push(firstHalfArr[i] + secondHalfArr[i]);
        }
        const formattedText = answer
          .join(" ")
          .replace(/\n\n/g, " <br><br/>")
          .replace(/\n/g, " <br><br/>");
        setText(formattedText);
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
      event.preventDefault();
      const inputText = event.target.elements["my-input"].value;
      const inputArray = inputText.split(" ");
      let spaces = [];
      for (let i = 0; i < inputArray.length; i++) {
        if (inputArray[i].includes("\n\n")) {
          let splitEntry = inputArray[i].split("\n\n");
          spaces.push(splitEntry[0] + "\n\n");
          spaces.push(splitEntry[1]);
        } else if (inputArray[i].includes("\n")) {
          let splitEntry = inputArray[i].split("\n");
          spaces.push(splitEntry[0] + "\n");
          spaces.push(splitEntry[1]);
        } else {
          spaces.push(inputArray[i]);
        }
      }
      let firstHalfArr = [];
      let secondHalfArr = [];
      spaces.forEach((word) => {
        let firstHalf = word.slice(0, Math.floor(word.length / 2));
        let secondHalf = word.slice(Math.floor(word.length / 2), word.length);
        firstHalfArr.push('<span class="bold">' + firstHalf + "</span>");
        secondHalfArr.push(secondHalf);
      });
      let answer = [];
      for (let i = 0; i < firstHalfArr.length; i++) {
        answer.push(firstHalfArr[i] + secondHalfArr[i]);
      }
      const formattedText = answer
        .join(" ")
        .replace(/\n\n/g, " <br><br/>")
        .replace(/\n/g, " <br><br/>");
      setText(formattedText);
      setIsLoading(false);
    }
  }

  function fileUploadHandler(event){
    setFile(event.target.files[0]);
    const myPhotoInput = document.getElementById("my-photo");
    const submitButton = document.getElementById("submit-photo");
      if (myPhotoInput.files.length > 0) {
        submitButton.classList.add("active");
      } else {
        submitButton.classList.remove("active");
      }
  }

  function textPasteHandler(event){
    const submitTextButton = document.getElementById("submit");
    if(event.target.value) {
          submitTextButton.classList.add("active");
        } else {
          submitTextButton.classList.remove("active");
        }
  }



  if (isLoading) {
    return (
      <div className="loader">
        <p>Just Loading...</p>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="head">
        <Logo />
        <SpeedReadr />
      </div>
      <div className="bio">
        <p>
          <strong>Ass</strong>istive <strong>techn</strong>ology{" "}
          <strong>i</strong>s <strong>t</strong>he <strong>diff</strong>erence{" "}
          <strong>bet</strong>ween <strong>stu</strong>dents <strong>wi</strong>
          th <strong>dysl</strong>exia <strong>be</strong>ing{" "}
          <strong>ab</strong>le <strong>t</strong>o <strong>part</strong>icipate{" "}
          <strong>i</strong>n <strong>cla</strong>ss <strong>a</strong>nd{" "}
          <strong>re</strong>ad <strong>indepe</strong>ndently,{" "}
          <strong>o</strong>r <strong>fal</strong>ling <strong>beh</strong>ind{" "}
          <strong>a</strong>nd <strong>beco</strong>ming <strong>disen</strong>
          gaged.
          <br></br>
          <br></br>
          <strong>Bio</strong>nic <strong>Rea</strong>ding <strong>to</strong>
          ols <strong>c</strong>an <strong>signif</strong>
          icantly <strong>imp</strong>rove <strong>a</strong>{" "}
          <strong>stu</strong>dent's <strong>compre</strong>hension,{" "}
          <strong>a</strong>nd <strong>pro</strong>vide <strong>gre</strong>ater{" "}
          <strong>acc</strong>ess <strong>t</strong>o <strong>curr</strong>
          iculum <strong>mate</strong>rials, <strong>help</strong>ing{" "}
          <strong>t</strong>o <strong>lev</strong>el <strong>t</strong>he{" "}
          <strong>pla</strong>ying <strong>fi</strong>eld <strong>a</strong>nd{" "}
          <strong>fos</strong>ter <strong>indep</strong>endence.
          <br></br>
        </p>
        <br></br>
      </div>
      <div className="instructions">
        <p>
          Add text to the box or upload an image. <br></br>
          Your text will then appear below the orange line.
        </p>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="image-upload-container">
          <label htmlFor="my-photo" className="file-upload-label">
            <p>Add Image</p>
          </label>
          <input
            type="file"
            id="my-photo"
            name="my-photo"
            accept="image/*"
            className="file-upload-input"
            onChange={fileUploadHandler}
          />
          <p className="file-name">
         {file.name}
          </p>
          <button type="submit" id="submit-photo" className="submit-button">
            Submit File
          </button>
        </div>
      </form>
      <form className="text_adder" onSubmit={handleFormSubmit}>
        <br></br>
        <textarea
          type="text"
          id="my-input"
          name="my-input"
          placeholder="  Add your text here..."
          onChange={textPasteHandler}
        />
        <br></br>
        <button type="submit" id="submit">
          Speed Read
        </button>
        <br></br>
      </form>
      <div className="boldText">
        <p
          className="outputText"
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
      </div>
    </div>
  );
}

export default App;
