
// function Convert(image){

// const Tesseract = require('tesseract.js');

//  return Tesseract.recognize(
//     image,
//     'eng',
//     { logger: m => (
//         console.log(Math.round(m.progress * 100))) }
//   ).then(({ data: { text }, }) => {

//     return text
//   })
 
// }

// export default Convert;



function Convert(image){
  const Tesseract = require('tesseract.js');

  return Tesseract.recognize(
    image,
    'eng',
    { logger: m => console.log(Math.round(m.progress * 100)) }
  ).then(({ data: { text }, progress: m }) => {
    return { text, m };
  });
}

export default Convert;
