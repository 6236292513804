function SpeedReadr() {
    return (
  
<svg  id="Layer_1" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 425.2 425.2" className="speedReadr" height="177">
<g>
	<g>
		<path d="M105.27,147.2c0,7.73-4.83,13.66-14.48,17.79c-7.73,3.31-16,4.97-24.83,4.97c-8.14,0-17.04-1.66-26.69-4.97
			c-12.41-4.27-18.62-9.59-18.62-15.93c0-3.45,1.66-5.17,4.97-5.17c2.62,0,5.65,1.52,9.1,4.55c4.83,4.55,7.45,6.97,7.86,7.24
			c5.24,3.45,13.72,5.17,25.45,5.17c15.72,0,23.59-4.14,23.59-12.41c0-3.86-3.38-8.62-10.14-14.28c-1.11-0.83-6.28-4.55-15.52-11.17
			c-14.76-10.48-24.07-17.79-27.93-21.93c-8.69-9.24-13.03-20-13.03-32.28c0-11.31,3.24-21.65,9.72-31.04
			c7.17-10.48,16.21-15.72,27.1-15.72c9.24,0,17.17,3.93,23.79,11.79c5.79,6.9,9.59,15.24,11.38,25.04
			c0.14,3.31,0.69,8.35,1.66,15.1l1.66,7.45c0,11.04-2.69,16.55-8.07,16.55c-1.38,0-2.79-0.79-4.24-2.38
			c-1.45-1.58-2.17-3.07-2.17-4.45c0-1.93,0.38-4.76,1.14-8.48c0.76-3.72,1.14-6.55,1.14-8.48c0-9.52-1.51-17.93-4.52-25.24
			c-3.97-9.93-9.99-14.9-18.06-14.9c-8.08,0-14.81,3.55-20.22,10.66c-5.41,7.11-8.11,14.86-8.11,23.28
			c0,11.31,5.91,22.07,17.74,32.28c10.86,7.86,21.8,15.8,32.8,23.79C99.42,132.58,105.27,140.3,105.27,147.2z"/>
		<path d="M176.65,114.09c0,13.66-2,25.11-6,34.35c-5.38,12.83-13.86,19.24-25.45,19.24c-7.59,0-14.21-3.52-19.86-10.55
			c-0.97,0.41-1.45,4.69-1.45,12.83c0,17.93,0.89,30.62,2.69,38.07c0.14,0.69,0.79,2.34,1.97,4.97c1.17,2.62,1.76,4.21,1.76,4.76
			c0,2.21-1.38,3.31-4.14,3.31c-3.31,0-5.62-0.48-6.93-1.45c-1.31-0.97-2.45-4.14-3.41-9.52c-0.83-4.55-1.24-8.14-1.24-10.76v-52.55
			c0-4.83,0.25-11.31,0.74-19.45c0.49-9.52,0.8-16,0.92-19.45c0.13-3.72,0.13-8.55,0-14.48c0-7.58,0-12.48,0-14.69
			c0-9.52,2.48-14.28,7.45-14.28c2.48,0,3.72,0.9,3.72,2.69c0,3.45-0.28,8.48-0.83,15.1c-0.55,6.62-0.83,11.59-0.83,14.9h0.83
			c3.17-4.27,6.34-8.62,9.52-13.03c3.73-4.83,8.34-7.24,13.86-7.24c9.24,0,16.21,4.14,20.9,12.41
			C174.72,95.89,176.65,104.16,176.65,114.09z M166.31,114.92c0-7.31-0.76-13.58-2.28-18.83c-2.35-7.72-6.14-11.59-11.38-11.59
			c-3.86,0-8.55,3.38-14.07,10.14c-4.83,5.93-8.21,12-10.14,18.21c-1.52,4.69-2.76,11.59-3.72,20.69c-0.55,6.62-0.83,9.8-0.83,9.52
			c0,0.41,0.62,1.11,1.85,2.07c0.82,0.55,1.65,1.17,2.47,1.86c2.2,3.17,4.43,6.38,6.69,9.62c2.26,3.24,5.66,4.86,10.19,4.86
			c7.55,0,13.25-6.21,17.09-18.62C164.93,134.03,166.31,124.72,166.31,114.92z"/>
		<path d="M247.62,144.3c0,6.07-4.07,11.52-12.21,16.35c-7.31,4.41-14.21,6.62-20.69,6.62c-18.49,0-27.73-11.72-27.73-35.17
			c0-10.35,1.86-21.45,5.59-33.31c4.97-15.45,11.31-23.17,19.04-23.17c6.62,0,13.86,3.35,21.73,10.04
			c7.86,6.69,11.79,13.34,11.79,19.97c0,8.55-3.45,16-10.34,22.35c-6.9,6.35-14.69,9.52-23.38,9.52c-0.83,0-2.59-0.69-5.28-2.07
			c-2.69-1.38-4.52-2.07-5.48-2.07c-1.79,0-2.69,0.55-2.69,1.66c0,15.86,5.24,23.79,15.72,23.79c5.38,0,10.34-1.79,14.9-5.38
			c2.76-2.07,5.8-5.31,9.1-9.72c2.62-3.45,4.34-5.17,5.17-5.17C246.03,138.51,247.62,140.44,247.62,144.3z M233.35,106.23
			c0-4.83-1.38-9.34-4.14-13.55c-2.76-4.21-6.41-6.31-10.96-6.31c-5.52,0-10,3.45-13.45,10.35c-2.9,5.79-4.34,11.79-4.34,18
			c0,4,1.27,7.35,3.83,10.04c2.55,2.69,5.83,4.03,9.83,4.03c5.65,0,10.34-2.41,14.07-7.24
			C231.62,117.13,233.35,112.02,233.35,106.23z"/>
		<path d="M317.55,144.3c0,6.07-4.07,11.52-12.21,16.35c-7.31,4.41-14.21,6.62-20.69,6.62c-18.49,0-27.73-11.72-27.73-35.17
			c0-10.35,1.86-21.45,5.59-33.31c4.97-15.45,11.31-23.17,19.04-23.17c6.62,0,13.86,3.35,21.73,10.04
			c7.86,6.69,11.79,13.34,11.79,19.97c0,8.55-3.45,16-10.34,22.35c-6.9,6.35-14.69,9.52-23.38,9.52c-0.83,0-2.59-0.69-5.28-2.07
			c-2.69-1.38-4.52-2.07-5.48-2.07c-1.79,0-2.69,0.55-2.69,1.66c0,15.86,5.24,23.79,15.72,23.79c5.38,0,10.34-1.79,14.9-5.38
			c2.76-2.07,5.79-5.31,9.1-9.72c2.62-3.45,4.34-5.17,5.17-5.17C315.97,138.51,317.55,140.44,317.55,144.3z M303.28,106.23
			c0-4.83-1.38-9.34-4.14-13.55c-2.76-4.21-6.41-6.31-10.96-6.31c-5.52,0-10,3.45-13.45,10.35c-2.9,5.79-4.35,11.79-4.35,18
			c0,4,1.27,7.35,3.83,10.04c2.55,2.69,5.83,4.03,9.83,4.03c5.65,0,10.34-2.41,14.07-7.24
			C301.55,117.13,303.28,112.02,303.28,106.23z"/>
		<path d="M392.45,33.21c0,3.18-0.41,7.26-1.24,12.23c-0.97,6.49-1.59,10.57-1.86,12.22c-0.41,4.28-1.1,18.51-2.07,42.68
			c-0.83,22.1-1.24,34.33-1.24,36.67c0,14.78,1.31,25.21,3.93,31.29c-0.97,1.52-2.21,2.28-3.72,2.28c-1.38,0-2.93-0.69-4.66-2.07
			c-1.73-1.38-2.79-2.76-3.21-4.14c0-2.76-0.41-6.96-1.24-12.62c-8.14,10.34-15.93,15.52-23.38,15.52
			c-8.97,0-15.86-3.38-20.69-10.14c-4.14-5.79-6.21-13.45-6.21-22.97c0-11.59,2.14-23.59,6.41-36
			c5.52-16.27,12.83-24.42,21.93-24.42c3.86,0,7.79,1.45,11.79,4.35c0.83,0.55,4.07,3.45,9.72,8.69c1.38-9.41,2.07-30.03,2.07-61.86
			c0.96-0.97,2.28-1.45,3.93-1.45c2.62,0,4.89,0.97,6.83,2.9C391.48,28.3,392.45,30.58,392.45,33.21z M376.93,109.75
			c0-6.07-1.38-11.52-4.14-16.35c-3.31-5.79-7.8-8.69-13.45-8.69c-6.9,0-12.41,7.03-16.55,21.1c-3.31,10.62-4.97,20.62-4.97,30
			c0,6.07,0.96,11.31,2.9,15.72c2.62,5.79,6.55,8.69,11.79,8.69c8.55,0,15.03-6.62,19.45-19.86
			C375.28,130.85,376.93,120.65,376.93,109.75z"/>
		<path d="M126.37,362.38v1.03c-0.83,1.38-2.01,2.07-3.53,2.07c-1.94,0-3.18-0.34-3.73-1.03c-1.11-5.65-10.44-14.65-28-27
			c-17.56-12.34-29.45-18.52-35.68-18.52H43.61v12.83c0,1.79,0.49,5.93,1.49,12.41c0.85,6.9,1.56,10.69,2.13,11.38
			c2.41,3.72,3.62,5.45,3.62,5.17c0,3.17-1.8,4.76-5.38,4.76c-4,0-7.03-1.45-9.1-4.34c-2.07-2.9-3.1-16.41-3.1-40.55
			c0-1.93-2.41-3.65-7.24-5.17c-4.83-1.52-7.24-3.31-7.24-5.38c0-3.03,4.41-5.79,13.24-8.28c0.96-4.97,1.58-11.59,1.86-19.86
			c0.55-10.35,0.96-17.03,1.24-20.07c0.13-1.52,0.76-5.65,1.86-12.41c0.96-5.1,1.38-9.31,1.24-12.62c-0.69-0.41-2.52-0.62-5.48-0.62
			c-2.97,0-4.45-1.17-4.45-3.52c0-2.9,1.38-4.69,4.14-5.38c1.93-0.55,3.93-1.1,6-1.66c0.41-1.38,1.31-2.9,2.69-4.55
			c1.24-0.41,2.34-0.62,3.31-0.62c1.52,0,3.07,0.76,4.66,2.28c1.58,1.52,3,2.28,4.24,2.28c0.69,0,1.66-0.1,2.9-0.31
			c1.24-0.21,2.21-0.31,2.9-0.31c15.31,0,28.21,3.17,38.69,9.52c12.83,7.45,19.24,18.35,19.24,32.69c0,11.86-3.52,21.52-10.55,28.97
			c-4.42,4.83-12.97,10.69-25.66,17.59c-0.83,0.14-1.79,0.49-2.9,1.04c2.76,2.62,8.96,7.1,18.62,13.45
			c9.65,6.34,16.55,11.52,20.69,15.52C123.61,349.48,126.65,355.9,126.37,362.38z M106.3,265.75c0-10.48-4.74-18.55-14.23-24.21
			c-7.84-4.69-17.32-7.03-28.46-7.03c-3.99,0-7.29,0.28-9.9,0.83l-2.68,4.14c-1.93,3.17-3.03,7.38-3.3,12.62
			c-0.28,7.59-0.55,12.07-0.82,13.45c-1.65,7.86-2.75,20.35-3.3,37.45c10.17,3.86,16.5,5.79,18.97,5.79c1.92,0,4.67-0.55,8.25-1.66
			c10.17-3.03,18.42-8.07,24.75-15.1C102.73,284.45,106.3,275.69,106.3,265.75z"/>
		<path d="M191.76,342.93c0,6.07-4.07,11.52-12.21,16.34c-7.31,4.41-14.21,6.62-20.69,6.62c-18.49,0-27.73-11.72-27.73-35.17
			c0-10.34,1.86-21.45,5.59-33.31c4.97-15.45,11.31-23.17,19.04-23.17c6.62,0,13.86,3.35,21.73,10.03
			c7.86,6.69,11.79,13.35,11.79,19.97c0,8.55-3.45,16-10.35,22.35c-6.9,6.35-14.69,9.52-23.38,9.52c-0.83,0-2.59-0.69-5.28-2.07
			c-2.69-1.38-4.52-2.07-5.48-2.07c-1.79,0-2.69,0.55-2.69,1.66c0,15.86,5.24,23.79,15.72,23.79c5.38,0,10.35-1.79,14.9-5.38
			c2.76-2.07,5.79-5.31,9.1-9.72c2.62-3.45,4.34-5.17,5.17-5.17C190.17,337.14,191.76,339.07,191.76,342.93z M177.48,304.86
			c0-4.83-1.38-9.34-4.14-13.55c-2.76-4.21-6.41-6.31-10.97-6.31c-5.52,0-10,3.45-13.45,10.35c-2.9,5.79-4.34,11.79-4.34,18
			c0,4,1.27,7.34,3.83,10.03c2.55,2.69,5.83,4.03,9.83,4.03c5.65,0,10.34-2.41,14.07-7.24
			C175.76,315.76,177.48,310.65,177.48,304.86z"/>
		<path d="M263.76,369.41c0,1.93-1.52,2.9-4.55,2.9c-3.31,0-5.31-1.38-6-4.14c-0.14-0.41-0.21-3.1-0.21-8.07
			c0-1.38-0.21-2.48-0.62-3.31c-7.73,5.66-16.07,8.48-25.04,8.48c-6.21,0-12.21-1.73-18-5.17c-6.62-3.86-9.93-8.69-9.93-14.48
			c0-5.66,3.93-11.17,11.79-16.55c7.17-4.97,13.79-7.45,19.86-7.45h20.48c0.83-0.83,1.24-2.28,1.24-4.35
			c0-9.24-0.76-16.21-2.28-20.9c-2.48-7.86-7.38-11.79-14.69-11.79c-5.24,0-10.07,3.31-14.48,9.93c-4,6.07-6,11.86-6,17.38
			c0,0.28,0,0.69,0,1.24c0,0.55,0,0.9,0,1.04c0,1.1-0.55,1.65-1.66,1.65c-1.38,0-2.9-0.83-4.55-2.48c-1.66-1.66-2.48-3.1-2.48-4.35
			c0-3.86,1.17-8.72,3.52-14.59c2.34-5.86,4.9-10.31,7.66-13.34c3.45-3.72,7.72-5.59,12.83-5.59c7.86,0,15.1,2.48,21.73,7.45
			c7.03,5.38,10.62,11.86,10.76,19.45L263.76,369.41z M252.79,336.52c0-5.52-4.28-8.28-12.83-8.28c-6.76,0-12.69,1.17-17.8,3.52
			c-6.48,3.03-9.72,7.59-9.72,13.65c0,3.72,1.72,6.62,5.17,8.69c2.9,1.79,6.34,2.69,10.34,2.69c5.93,0,11.55-1.93,16.86-5.79
			C250.14,347.14,252.79,342.31,252.79,336.52z"/>
		<path d="M341.76,231.83c0,3.18-0.41,7.25-1.24,12.23c-0.97,6.49-1.59,10.57-1.86,12.22c-0.41,4.28-1.1,18.51-2.07,42.68
			c-0.83,22.1-1.24,34.33-1.24,36.67c0,14.78,1.31,25.21,3.93,31.29c-0.97,1.52-2.21,2.28-3.72,2.28c-1.38,0-2.93-0.69-4.66-2.07
			c-1.73-1.38-2.79-2.76-3.21-4.14c0-2.76-0.41-6.96-1.24-12.62c-8.14,10.34-15.93,15.52-23.38,15.52
			c-8.97,0-15.86-3.38-20.69-10.14c-4.14-5.79-6.21-13.45-6.21-22.97c0-11.59,2.14-23.59,6.41-36
			c5.52-16.27,12.83-24.42,21.93-24.42c3.86,0,7.79,1.45,11.79,4.35c0.83,0.55,4.07,3.45,9.72,8.69c1.38-9.41,2.07-30.03,2.07-61.86
			c0.96-0.97,2.28-1.45,3.93-1.45c2.62,0,4.89,0.97,6.83,2.9C340.8,226.93,341.76,229.21,341.76,231.83z M326.24,308.38
			c0-6.07-1.38-11.52-4.14-16.34c-3.31-5.79-7.8-8.69-13.45-8.69c-6.9,0-12.41,7.03-16.55,21.1c-3.31,10.62-4.97,20.62-4.97,30
			c0,6.07,0.96,11.31,2.9,15.72c2.62,5.79,6.55,8.69,11.79,8.69c8.55,0,15.03-6.62,19.45-19.86
			C324.59,329.48,326.24,319.28,326.24,308.38z"/>
		<path d="M398.04,289.76c0,0.55-0.07,1.73-0.21,3.52c-1.79,2.76-3.52,4.14-5.17,4.14c-1.11,0-2.07-0.69-2.9-2.07
			c-0.41-8.69-4-13.03-10.76-13.03c-4.42,0-8.76,8.14-13.03,24.41c-1.65,5.93-2.48,13.86-2.48,23.8c0,12.69,0.34,20.48,1.03,23.38
			c0.14,0.41,0.48,1.28,1.03,2.59c0.55,1.31,0.83,2.31,0.83,3c0,3.04-2.07,4.55-6.21,4.55c-4.69,0-7.04-2.41-7.04-7.24
			c0-0.69,0.17-1.69,0.52-3c0.34-1.31,0.52-2.31,0.52-3c0-7.58-0.35-18.96-1.04-34.14c-0.69-15.17-1.03-26.55-1.03-34.14
			c0-2.76,0.48-4.45,1.45-5.07c0.96-0.62,2.83-0.93,5.59-0.93c3.45,0,5.45,1.24,6,3.72c0.14,2.76,0.28,5.45,0.41,8.07
			c3.59-5.52,5.65-8.62,6.21-9.31c3.03-3.45,6.21-5.17,9.52-5.17c2.48,0,4.97,0.76,7.45,2.28c1.38,0.83,3.28,2.97,5.69,6.41
			C396.83,285.96,398.04,288.38,398.04,289.76z"/>
	</g>
</g>
</svg>

    )}
  
  export default SpeedReadr;